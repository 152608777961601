import React from "react"

// Libraries
import { graphql, useStaticQuery } from "gatsby"

// Components
import HeroWithGradient from "components/hero-with-gradient"

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "events/event-background.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1440, quality: 100)
        }
      }
    }
  `)

  return (
    <HeroWithGradient
      title="Conferences and Events"
      image={image}
      link="#events"
    />
  )
}

export default Hero
