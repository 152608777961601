import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoints from "utils/breakpoints"
import PropTypes from "prop-types"
import { colors } from "utils/variables"

// Components
import EventsCard from "components/events-card"
import Container from "components/container"

const StyledPastEvents = styled.section`
  padding: 64px 0;
  background-color: ${colors.silver__extra_pale};

  ${breakpoints.medium`
    padding: 120px 0;
  `}

  .title-section {
    margin-bottom: 24px;

    ${breakpoints.medium`
    
      margin-bottom: 48px;
    `}
  }

  .event-card {
    margin-bottom: 24px;

    ${breakpoints.small`
      margin-bottom: 40px;
    `}

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const PastEvents = (props) => {
  const { events } = props

  return (
    <StyledPastEvents>
      <Container>
        <h2 className="mb-5 h1 title-section">Past Events</h2>

        {events &&
          events.map((event) => (
            <div className="event-card">
              <EventsCard {...event} />
            </div>
          ))}
      </Container>
    </StyledPastEvents>
  )
}

PastEvents.propTypes = {
  events: PropTypes.arrayOf({
    featuredImage: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    excerpt: PropTypes.shape({ excerpt: PropTypes.string.isRequired })
      .isRequired,
    eventUrl: PropTypes.string.isRequired,
  }).isRequired,
}

export default PastEvents
