// import React, { useEffect } from "react"
import React, { useEffect, useState } from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"

// Layout
import Layout from "layouts/layout-primary"

// Sections
import Hero from "sections/events/hero"
import UpcomingEvents from "sections/events/upcomingEvents"
import PastEvents from "sections/events/pastEvents"

// Components
import SEO from "components/seo"

const Events = () => {
  const [upcomingEvents, setUpcomingEvents] = useState(null)
  const [pastEvents, setPastEvents] = useState(null)

  const {
    events: { nodes: events },
  } = useStaticQuery(graphql`
    query {
      events: allContentfulEvents(sort: { order: ASC, fields: startDate }) {
        nodes {
          eventUrl
          finishDate(formatString: "MMM D, YYYY")
          startDate(formatString: "MMM D, YYYY")
          title
          location
          excerpt {
            excerpt
          }
          featuredImage {
            gatsbyImageData(height: 264, quality: 100)
          }
        }
      }
    }
  `)

  useEffect(() => {
    const now = moment()

    const newPastEvents = []
    const newUpcomingEvents = []

    events.forEach((event) => {
      /**
       * Si la fecha del evento es HOY o en el futuro, lo agrego
       * al array de `upcomingEvents`
       *
       * Sino, lo agrego al array de `pastEvents`.
       */

      if (now.diff(event.finishDate) < 0) {
        newUpcomingEvents.push(event)
      } else {
        newPastEvents.unshift(event)
      }
    })
    setUpcomingEvents(newUpcomingEvents)
    setPastEvents(newPastEvents)
  }, [])

  return (
    <Layout showNewsletter={false}>
      <SEO title="Events | Arena Analytics" />
      <Hero />
      {upcomingEvents && <UpcomingEvents events={upcomingEvents} />}
      {pastEvents && <PastEvents events={pastEvents} />}
    </Layout>
  )
}

export default Events
