import React from "react"

// Labraries
import styled from "styled-components"
import PropTypes from "prop-types"

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Icons
import Location from "assets/icons/events/icon-location.inline.svg"
import Calendar from "assets/icons/events/icon-calendar.inline.svg"
import Arrow from "assets/icons/icon-arrow-right--small.inline.svg"

// Utils
import breakpoints from "utils/breakpoints"

const StyledEventsCard = styled.a`
  border-radius: 16px;
  &:hover {
    box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.3);
  }

  .img {
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;

    ${breakpoints.small`
      border-radius: 16px 0px 0px 16px;
    `}
  }

  .card-content {
    padding: 32px;
    border-radius: 0px 0px 16px 16px;
    background-color: white;
    height: 100%;
    display: flex;
    align-items: center;

    ${breakpoints.small`
      border-radius: 0px 16px 16px 0px;
    `}

    ${breakpoints.medium`
      padding: 52px 40px;
    `}

    .date {
      display: flex;
      flex-direction: column;

      ${breakpoints.medium`
        flex-direction: row;
        p {
          :first-of-type {
            margin-right: 21px;
          }
        }
      `}

      svg {
        margin-right: 8px;
      }
    }

    .view-more {
      svg {
        margin-left: 4px;
      }
    }
  }
`

const EventsCard = (props) => {
  const {
    eventUrl,
    finishDate,
    startDate,
    title,
    location,
    featuredImage,
    excerpt,
  } = props
  const image = getImage(featuredImage)
  return (
    <StyledEventsCard href={eventUrl} target="_blank" rel="noreferrer noopener">
      <div className="row g-0">
        <div className="col-12 col-sm-6 col-md-4">
          <GatsbyImage className="img" image={image} alt={image} />
        </div>

        <div className="col-12 col-sm-6 col-md-8">
          <div className="card-content">
            <div>
              <p className="font-weight--700 mb-2 h2">{title}</p>
              <div className="date d-flex">
                {startDate === finishDate ? (
                  <p className="font-weight--700 mb-2 d-flex align-items-center">
                    <Calendar />
                    {finishDate}
                  </p>
                ) : (
                  <p className="font-weight--700 mb-2 d-flex align-items-center">
                    <Calendar /> {startDate} - {finishDate}
                  </p>
                )}
                <p className="mb-2 d-flex align-items-center">
                  <Location />
                  {location}
                </p>
              </div>
              <p className="color--silver__dark mb-4 col-lg-12">
                {excerpt
                  ? excerpt.excerpt
                  : "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula natoque penatibus et magnis dis.."}
              </p>
              <p className="view-more font-weight--700 color--turquoise__primary d-flex align-items-center color-hover--silver__primary svg-hover--stroke-turquoise__primary">
                View more <Arrow />
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledEventsCard>
  )
}
EventsCard.propTypes = {
  featuredImage: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  finishDate: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  excerpt: PropTypes.shape({ excerpt: PropTypes.string.isRequired }).isRequired,
  eventUrl: PropTypes.string.isRequired,
}

export default EventsCard
