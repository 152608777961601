import React, { useState } from "react"

// Libraries
import styled from "styled-components"
import PropTypes from "prop-types"

// Utils
import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

// Components
import EventsCard from "components/events-card"
import Container from "components/container"
import Button from "components/button"

// Icons
import Chevrondown from "assets/icons/events/chevron-down.inline.svg"

const StyledUpcomingEvents = styled.section`
  padding: 64px 0;
  background-color: ${colors.silver__pale};

  ${breakpoints.medium`
    padding: 120px 0;
  `}

  .title-section {
    margin-bottom: 24px;

    ${breakpoints.medium`
      margin-bottom: 48px;
    `}
  }

  .event-card {
    margin-bottom: 24px;

    ${breakpoints.small`
      margin-bottom: 40px;
    `}

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .upcomming-button {
    text-align: center;
    margin-top: 40px;
    button {
      background-color: ${colors.silver__extra_pale};
    }

    ${breakpoints.medium`
      margin-top: 58px;
    `}
    svg {
      margin-left: 8px;
      padding: 6px 4px;
    }
  }
`

const UpcomingEvents = (props) => {
  const { events } = props

  const [showMore, setShowMore] = useState(4)

  const eventsToShow = events.slice(0, showMore)

  const handleClick = () => {
    setShowMore(showMore + 4)
  }

  return (
    <StyledUpcomingEvents id="events">
      <Container>
        <h2 className="text-align-start h1 title-section">Upcoming Events</h2>
        {eventsToShow &&
          eventsToShow.map((event) => (
            <div className="event-card">
              <EventsCard {...event} />
            </div>
          ))}
        <div className="upcomming-button">
          {events.length > showMore && (
            <Button
              type="button"
              onClick={() => handleClick()}
              level="tertiary"
              className="button svg--stroke-turquoise__primary"
            >
              Load more events
              <Chevrondown />
            </Button>
          )}
        </div>
      </Container>
    </StyledUpcomingEvents>
  )
}

UpcomingEvents.propTypes = {
  events: PropTypes.arrayOf({
    featuredImage: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    excerpt: PropTypes.shape({ excerpt: PropTypes.string.isRequired })
      .isRequired,
    eventUrl: PropTypes.string.isRequired,
  }).isRequired,
}

export default UpcomingEvents
