import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import BackgroundImage from "components/background-image/"
import HeroArrow from "components/hero-arrow"

// Icons
import IconHero from "assets/icons/components/hero-with-gradient/icon-hero.svg"

const StyledHeroWithGradient = styled(BackgroundImage)`
  width: 100%;
  height: 344px;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 37, 72, 0) 0%, #002548 100%);
  background-size: cover;
  background-repeat: no-repeat;

  ${breakpoint.small`
    height: 440px;
  `}

  .hero__arrow {
    .arrow__illustration {
      svg {
        * {
          fill: ${colors.navy__extra_dark};
        }
      }
    }
  }

  .hero__icon {
    height: 70px;
    display: block !important;
    position: absolute;
    right: 0;
    bottom: 8px;

    ${breakpoint.small`
      height: 166px;
    `}
  }
`

const HeroWithGradient = (props) => {
  const { image, title, link } = props

  return (
    <StyledHeroWithGradient
      image={image}
      className="d-flex align-items-center color--white text-center"
    >
      <Container>
        <h1 className="h1 maxi">{title}</h1>
      </Container>

      <HeroArrow link={link} className="hero__arrow" />

      <img src={IconHero} className="hero__icon d-none d-md-block" alt="" />
    </StyledHeroWithGradient>
  )
}

HeroWithGradient.propTypes = {
  image: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default HeroWithGradient
